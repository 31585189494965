import React, { useState } from "react";
import LabeledText from "../layout/LabeledText";
import RoundOff from "../utils/RoundOff";
import NumberFormatter from "../utils/NumberFormatter";
import "../App.scss";
// Battery Options
import BatteryOptions from "../utils/BatteryOptions";
// wattage standard plans file
import StandardPlan from "../utils/StandardPlans";



const App = () => {
  
  const [wplans, setwplan] = useState();
  const [wattage, setWattage] = useState();
  const [type,setType] =useState();

  const wattage_sum = wattage / wplans;
  const round = RoundOff(wattage_sum) / 1 || 0;
  const round_1 = round.toFixed(1);

  // Divide the answer of (3) by the constant 0.95
  const const_value = round / 0.95;
  const const_value_round = RoundOff(const_value);
  const const_value_round_1 = const_value_round.toFixed(1);
 
  const onChangeType =(e)=>{
    const selectedId = e.target.value;
    const selectedType = BatteryOptions.filter((option)=>option.id == selectedId)[0];
    setType(selectedType)
  }

  return (
    <div className="opex-calculator-container">
      <h2 className="title">Opex Calculator - Off Grid</h2>
      <div className="input-fields">
        <div>
           <LabeledText
            value={wattage}
            onChange={setWattage}
            placeholder="Wattage Of The System (W)"
          />
          <p>Wattage (W)</p>
        </div>

        <div className="item-dropdown">
          {/* selecting option of Batteries */}
          <div className="dropdown">
            <select
              className="fielditem"
              onChange={(e)=>{
                onChangeType(e);
              }}
            
            >
              {BatteryOptions.map((option) => (
                <option key={option.id} value={option.id}>{option.label}</option>
              
              ))}
            </select>
            <p>Battery Type</p>
          
          </div>
          
          {/* Wattage plans */}

          <div className="dropdown">
            <select
              className="fielditem"
              value={wplans}
              onChange={(e) => setwplan(e.target.value)}
            >
              {StandardPlan.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
            <p> Invertor Capacity (kW)</p>
          </div>
        </div>
        



        


      </div>

      {/* <h6>Text Sample</h6>
      <h2 className="red">{round_1}</h2> */}
      {/* ---------------------- */}
      {/* <h6>Text Sample</h6> */}
      {/* <p>(114 average monthly generation)</p> */}
      <h2 className="red">{const_value_round_1}</h2>
      {/* ---------------------- */}
      <p className="text">Please use the value in red to find the approximate number of hours</p>
      
      
      {type && wattage && wplans ? (<img src={type?.img} width="auto" height="auto" />) :("")}
      
    </div>
  );
};

export default App;
