const StandardPlan = [
    {
        label:"Select The Wattage",
        value:"Select The Wattage"
    },
    {
        label:"1 kW",
        value:6
    },
    {
        label:"2 kW",
        value:12
    },

    {
        label:"3 kW",
        value:12
    },
    {
        label:"5 kW",
        value:24
    },
    
]

export default StandardPlan;