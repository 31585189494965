
import Calculate from './components/Calculate';
import './App.scss';

function App() {
  return (
    <>
    <Calculate/>
    </>
  );
}

export default App;
