import React from 'react';
import PropTypes from 'prop-types';

const LabeledText = (props) => {
  const { onChange, value, label, type, disabled ,placeholder} = props;

  const _onChange = (e)=>{
    onChange(e.target.value);
  }

  return (
    <div className="field-item">
      <input disabled={disabled} onChange={_onChange} value={value} type={type} placeholder={placeholder}/>
      <label>{label}</label>
    </div>
  )
};

LabeledText.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
};

LabeledText.defaultProps = {
  type: 'text',
  disabled: false,
};

export default LabeledText;
