const BatteryOptions =[
    {
        id:1,
        label:"Select The Battery",
        value:"Select The Battery",
        img:''
    },
    {
        id:2,
        label:"Exide",
        value:"Exide",
        img:'https://www.threesinhasolar.lk/wp-content/uploads/2023/01/exide-300x71.png'
    },
    {
        id:3,
        label:"JYC",
        value:"JYC",
        img:"https://www.threesinhasolar.lk/wp-content/uploads/2023/01/mustandjyc.png"
    },
    {
        id:4,
        label:"Must",
        value:"Must",
        img:"https://www.threesinhasolar.lk/wp-content/uploads/2023/01/mustandjyc.png",
    },
   
]
export default BatteryOptions;